import { List, Map } from "immutable";
import { max } from "lodash";
import { useEffect, useMemo } from "react";
import { useInsightsContext } from "context/insightsContext";
import { InsightsIdentityModel } from "models/InsightsIdentityModel";
import type { InsightsDetectionModel } from "models/InsightsDetectionModel";
import type { UserModel } from "models/UserModel";

type TUpdateInsightsUsers = {
	idByEmail: Map<string, string> | null;
	users: Map<string, UserModel | null>;
	updateUsers: (users: Partial<UserModel>[]) => void;
	identities?: List<InsightsIdentityModel> | null;
	detections?: List<InsightsDetectionModel> | null;
	additionalUpdatedUsersMap?: Map<string, Partial<UserModel>>;
	filter?: boolean; // Filter users that already have insights data
};

export const useUpdateInsightsUsers = ({
	idByEmail,
	updateUsers,
	users,
	additionalUpdatedUsersMap,
	identities,
	detections,
	filter = true
}: TUpdateInsightsUsers) => {
	const {
		state: { isInsightsEnabled },
		actions: { addPartialUsers }
	} = useInsightsContext();

	const identitiesUsersMap = useMemo(() => {
		let usersToUpdate = Map<string, Partial<UserModel>>();
		if (!identities || !idByEmail) return usersToUpdate;

		identities?.forEach(identity => {
			const userId = idByEmail.get(identity.email.toLowerCase());
			const detections = identity.accounts?.flatMap(account => account.detections);
			if (!userId || !detections) return;

			const user = users.get(userId);
			if (filter && user?.insightsData?.fullDetectionsData) return;

			usersToUpdate = usersToUpdate.set(userId, {
				id: userId,
				email: identity.email,
				insightsData: {
					identityId: identity.id,
					detections,
					sensitivity: identity.sensitivity,
					fullDetectionsData: true
				}
			});
		});

		return usersToUpdate;
	}, [filter, idByEmail, identities, users]);

	const detectionsUsersMap = useMemo(() => {
		let usersToUpdate = Map<string, Partial<UserModel>>();
		if (!detections || !idByEmail) return usersToUpdate;

		detections.forEach(detection => {
			const userId = idByEmail.get(detection.email.toLowerCase());
			if (!userId) return;

			const user = users.get(userId);
			if (filter && user?.insightsData?.detections) return;

			const updatedUser = usersToUpdate.get(userId);
			let userDetections = updatedUser?.insightsData?.detections || List<InsightsDetectionModel>();
			userDetections = userDetections.push(detection);
			usersToUpdate = usersToUpdate.set(userId, {
				id: userId,
				email: detection.email,
				insightsData: { detections: userDetections }
			});
		});

		return usersToUpdate;
	}, [detections, filter, idByEmail, users]);

	const updatedUsersMap = useMemo(() => {
		const mergedMaps = identitiesUsersMap.merge(detectionsUsersMap);
		return additionalUpdatedUsersMap ? mergedMaps.mergeDeep(additionalUpdatedUsersMap) : mergedMaps;
	}, [identitiesUsersMap, detectionsUsersMap, additionalUpdatedUsersMap]);

	const { insightsUsersNotInContext, insightsUsersExistsInContext, allInsightsUsers } = useMemo(() => {
		const updatedUsersWithInsightsData = updatedUsersMap.valueSeq().map(user => {
			const detections = user.insightsData?.detections;
			const detectionsSeverities = detections?.map(detection => detection.severity).toArray();

			return {
				...user,
				insightsData: {
					...(user.insightsData || {}),
					maxSeverity: detectionsSeverities ? Math.round(max(detectionsSeverities) || 0) : 0,
					sensitivity: user.insightsData?.sensitivity || 0
				}
			} as Partial<UserModel>;
		});

		const [insightsUsersNotInContext, insightsUsersExistsInContext] = updatedUsersWithInsightsData.partition(
			user => user.id && users.has(user.id)
		);

		return {
			insightsUsersNotInContext: insightsUsersNotInContext.toArray(),
			insightsUsersExistsInContext: insightsUsersExistsInContext.toArray(),
			allInsightsUsers: updatedUsersWithInsightsData.toArray()
		};
	}, [updatedUsersMap, users]);

	// Effect to update users with insights data that are already in the users context, and add those that are not to the partial state
	useEffect(() => {
		if (!isInsightsEnabled || (!insightsUsersExistsInContext.length && !insightsUsersNotInContext.length)) return;

		if (insightsUsersExistsInContext.length) {
			updateUsers(insightsUsersExistsInContext);
		}
		if (insightsUsersNotInContext.length) {
			addPartialUsers(insightsUsersNotInContext);
		}
	}, [isInsightsEnabled, insightsUsersExistsInContext, insightsUsersNotInContext, addPartialUsers, updateUsers]);

	return { insightsUsersExistsInContext, insightsUsersNotInContext, allInsightsUsers };
};
